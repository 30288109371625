@import '../typography.scss';
@import '../../../variables.scss';

// This file contains styles specific to the question editor that is rendered element is focused for editing.
// The idea to have consistent styles.

.editor-widget {
  padding-bottom: $size-xxlarge;
}

.editor-divider {
  padding-bottom: $size-medium;
}

.mat-icon.editor-trash-icon,
.editor-trash-icon {
  height: 35px;
  width: 24px;
  min-width: 24px;
  margin-left: $size-micro;
  margin-right: $size-micro;
}

.editor-container {
  display: grid;
  grid-template-rows: auto 50px;
  height: 100%;

  .editor-content {
    overflow: auto;
    padding: var(--rightBarPadding);
    padding-bottom: 0;
  }

  .editor-actions {
    padding: 0 10px;
    border-top: 1px solid $color-outline !important;
  }
}
