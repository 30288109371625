/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use '../node_modules/bootstrap/scss/bootstrap';
@import 'ngx-diff/styles/default-theme';
@import 'helpers';
@import '_theme';
@import 'fonts';
@import 'breakpoints';
@import 'variables';
@import 'assets/styles/typography.scss';
@import 'assets/styles/elements/widgets.scss';
@import 'assets/styles/elements/buttons.scss';
@import 'assets/styles/elements/inputs.scss';
@import 'assets/styles/elements/editor.scss';
@import 'assets/styles/elements/question.scss';
@import 'assets/styles/elements/question_builder.scss';

:root {
  --box-shadow: 0 6px 10px 0 #666666;
  --main-header-height: 4rem;
  --secondary-header-height: 66px;
  --secondary-header-height-small: 38px;
  --main-footer-height: 30px;
  --card-width: 350px;
  --card-height: 170px;
  --card--header-height: 60px;
  --card--footer-height: 60px;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-text-button-label-text-tracking: normal;
  --sidenav-width: 245px;
  --rightPanelWidth: 325px;
}

mat-mdc-toolbar-row {
  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }
}

html,
body {
  height: 100%;
}

body {
  background: mat.m2-get-color-from-palette($mat-white, 900);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

//Some extra resets

button:focus {
  outline: none;
}

.color {
  &.gray {
    color: mat.m2-get-color-from-palette($mat-white, 900);
  }
}

a {
  &.clean-mfm-href {
    text-decoration: none !important;
  }
}

.full-dialog-content {
  margin: -24px;
  padding: 0;
}

.fullscreen-dialog {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  border-radius: 0;
}

mat-mdc-tab-group {
  &.mfm-theme-primary {
    mat-mdc-ab-header {
      background-color: mat.m2-get-color-from-palette($primary, 700);
      color: mat.m2-get-color-from-palette($mat-white, 100);

      div {
        &.ng-star-inserted {
          opacity: 1;
          color: mat.m2-get-color-from-palette($mat-white, 100);

          &.mat-mdc-tab-label-active {
            color: mat.m2-get-color-from-palette($mat-black, 900, 1) !important;
          }
        }
      }
    }

    mat-mdc-ink-bar {
      background-color: mat.m2-get-color-from-palette($primary, 900, 1) !important;
    }
  }
}

mat-mdc-form-field {
  width: 100%;
  line-height: normal;

  .mat-mdc-form-field-infix {
    width: 100%;
  }
}

.mat-mdc-menu-panel {
  .mat-mdc-menu-content {
    padding: 0;
  }
}

mat-mdc-select {
  line-height: normal;
}

@include scrollbar;

.context-mainContent {
  padding: 0;
  // margin-top: var(--secondary-header-height);
}

.mfm-paper-content {
  background: mat.m2-get-color-from-palette($mat-white, 50);
  border-radius: 5px;
  margin-right: 0;
  margin-left: 0;
  overflow: auto;
}

.background-success {
  background: $color-green !important;
}

.background-warn {
  background: $color-red !important;
}

.background-warning {
  background: $color-orange-light !important;
}

.text-color-success {
  background: mat.m2-get-color-from-palette($successText);
}

.mfm-second-header {
  top: var(--main-header-height);
  left: 0;
  padding: 0 !important;
  height: var(--secondary-header-height);
  background-color: mat.m2-get-color-from-palette($mat-white, 50) !important;
  box-shadow: 0 1px 0px 0 mat.m2-get-color-from-palette($mat-white, A400, 0.3) !important;
  z-index: 4;
  display: flex;
  justify-content: center;

  &--no-header {
    top: 0;
  }

  &_left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    flex: 1;
    margin-right: auto;
  }

  &_center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &_right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    flex: 1;
    margin-left: auto;
  }

  h1 {
    padding: 0 28px;
    @extend .me-4;
    @extend .d-flex;
    @extend .align-items-center;
    height: 100%;
  }
}

.list-page-cards {
  //height: calc(100vh - 168px);

  &--no-header {
    margin-top: 0;
  }
}

.assign-form {
  &_backdrop {
    background: rgba(0, 0, 0, 0.32);

    + .cdk-global-overlay-wrapper {
      overflow: auto;
    }
  }
}

.scroll-holder {
  overflow-y: auto;
  height: calc(100vh - var(--secondary-header-height) - var(--main-header-height) - var(--main-footer-height));

  &--no-header {
    height: calc(100vh - var(--secondary-header-height));
  }

  &--inSubContext {
    height: 100%;
  }
}

.snackbar {
  &--alert-background {
    background: mat.m2-get-color-from-palette($warn);
    color: mat.m2-get-color-from-palette($mat-black, A700);
  }
}

.color-icon {
  color: $color-black;
}

.more {
  @extend .color-icon;
}

.mfm-tlm {
  height: 100%;

  &_link,
  .mfm-tlm_link {
    height: 100%;

    span.mdc-button__label {
      display: inline-block;
      height: 100%;
      padding: 2px 0;
      color: mat.m2-get-color-from-palette($mat-black, A700);
    }

    &:disabled span.mdc-button__label {
      color: inherit;
    }

    &:not(:disabled):hover,
    &:not(:disabled).active {
      span {
        color: mat.m2-get-color-from-palette($mf-blue, A700);
      }

      .mat-mdc-button-focus-overlay {
        background: none;
      }

      span.mdc-button__label {
        padding: 2px 0 0;
        color: $color-menu-active;
        border-bottom: 2px solid $color-menu-active;
      }
    }
  }

  &-wrapper {
    border-bottom: 1px solid $color-line;
  }
}

.flex-1 {
  flex: 1;
}

.details-section-title {
  margin: 20px 0;
  height: 34px;
  background: #f4f7f9;
}

.custom-hint-wrapper {
  color: rgba(18, 18, 18, 0.56);
}

.custom-hint {
  display: flex;
  font-size: 14px;

  line-height: 14px;
  color: rgba(18, 18, 18, 0.56);

  mat-icon {
    width: 16px !important;
    margin-right: 4px;
  }
}

.mat-mdc-chip {
  &.mat-mdc-primary {
    background: mat.m2-get-color-from-palette($primary, 100);
    color: mat.m2-get-color-from-palette($mat-white, 100);

    mat-icon {
      color: mat.m2-get-color-from-palette($mat-white, 100) !important;
    }
  }
}

.mat-mdc-tooltip {
  max-width: fit-content !important;
}

.mat-mdc-header-cell,
.mat-mdc-cell,
.mat-mdc-footer-cell {
  padding: 0 10px;
}

.context-mainContent_open {
  mfm-information-stripe {
    position: absolute;
    width: calc(100vw - 200px);
    z-index: 12;
  }
}

.context-mainContent_close {
  mfm-information-stripe {
    position: absolute;
    width: calc(100vw - 56px);
    z-index: 12;
  }
}

.no-margin {
  label {
    margin-bottom: 0;
    margin-right: 5px;
  }
}

.CodeMirror-lint-tooltip {
  z-index: 1001 !important;
}

// summernote fixes
div.note-modal-backdrop {
  display: none !important;
}

.note-modal.open {
  background: rgba(0, 0, 0, 0.5);
}

button.note-btn.dropdown-toggle::after {
  display: none;
}

.note-editable {
  background-color: #fff;
}

.color-preview {
  width: 50px;
  border: 1px solid #3c3c3c;
  height: 26px;
  display: inline-block;
  float: right;
}

.mfm-tooltip {
  font-size: 11px;
  max-width: 200px !important;
}

.vw-100-scroll {
  min-width: 1080px;
}

.create-new-field-option {
  background: whitesmoke !important;
  color: #6d91f7 !important;
  border-bottom: 1px solid hsla(204, 80%, 72%, 0.1);
  font-size: 85%;
}

table.filtered-table {
  td.mat-mdc-cell {
    font-size: 14px;
    @include for-phone-only() {
      font-size: 11px;
      word-break: break-word;
    }
  }

  tr.expanded-row {
    td {
      background: #f5f5f5;
    }
  }

  .cdk-column-projectRecordID {
    width: 10%;
    @include for-phone-only {
      padding-left: 19px !important;
    }
  }

  .cdk-column-action {
    width: 2%;
    @include for-phone-only {
      padding-right: 0 !important;
    }
  }

  .element-row:not(.expanded-row) td {
    @extend .material-td !optional;
  }

  .cdk-column-userId {
    padding-right: 24px;
  }

  .expanded-row td {
    @extend .material-td !optional;
  }

  .element-detail-wrapper {
    overflow: hidden;

    .record-form {
      color: #c7c9cd;
    }

    @include for-phone-only {
      .record-form {
        font-size: 13px;
      }
    }
  }

  .element-detail {
    padding: 8px;
    @include for-phone-only {
      padding: 0;
    }
    margin: 8px 0;

    &.highlight {
      background-color: mat.m2-get-color-from-palette($mf-green, 500, 0.3);
    }
  }

  .meta-form-list {
    overflow: auto;
    height: 100%;
  }

  .header-cell {
    font-weight: 700;
    font-size: 14px;

    .mat-mdc-sort-header-container {
      button.mat-sort-header-button {
        @include for-phone-only() {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
}

.no-data-field {
  color: mat.m2-get-color-from-palette($warn);
}

.full-screen-dialog {
  max-width: 100vw !important;
  box-sizing: border-box;
  overflow: hidden;

  .mdc-dialog__container {
    border-radius: 0;
    width: 100vw;
    height: 100vh;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.assign-form-dialog {
  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
  mat-dialog-container {
    overflow-x: hidden;
  }
}

// PHARMATRAIL

// SIDEBAR

mat-sidenav-container,
mat-sidenav-content,
mat-sidenav {
  height: 100%;
}

mat-sidenav {
  width: 15vw;
}

main {
  padding: 10px;
  width: 100%;
}

// Active link
a {
  &.active {
    // TODO: take c`olor from the branding
    background-color: #e3e7f2 !important;
    border-radius: $size-mini;
  }
}

// MENU EXpander
.mat-mdc-expansion-panel-menu {
  .mat-mdc-expansion-panel-body {
    padding: 0 32px 0px !important;
  }
}

mat-toolbar {
  &.page-toolbar {
    border-bottom: none;
    background-color: #ffffff !important;
    height: $size-header;
  }
}

a.phar-back-link {
  display: inline-flex;
  align-items: center;
  padding: 0 $size-small;
  height: 40px;
  color: $color-black;
  font-weight: bold;
  text-decoration: none;
}

.button-with-icon {
  mat-icon {
    margin-left: 15px;
    margin-right: 10px;
    width: 15px;
  }
}

mat-icon {
  width: 24px;
  height: 24px;
}

//dialog
.mdc-dialog__container {
  .mat-mdc-dialog-surface {
    overflow: hidden;
  }
}

div.mat-mdc-menu-panel.comments-container {
  max-width: 350px;
  width: 400px;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  line-height: normal !important;
  word-break: break-word;
}

.remove-will-change {
  will-change: unset;

  .mdc-text-field {
    will-change: unset;
  }
}

.datepicker-toggle {
  button {
    height: 38px !important;
    padding: 0 !important;
  }
}

.range-picker {
  .mat-datepicker-toggle {
    button {
      height: 38px !important;
      padding: 0 !important;
    }
  }
}

.phar-tooltip {
  --mdc-plain-tooltip-container-color: #{mat.m2-get-color-from-palette($mat-black, 600, 0.9)};
  //.mdc-tooltip_surface {
  white-space: pre-line !important;
  //}
}

.required-field:after {
  content: '*';
  margin-left: 2px;
  color: $color-red;
}

#apply-values {
  background: $color-green;
  padding: 8px 0;
  color: $color-white;
}

.custom-select-panel {
  max-height: 215px;
  overflow: auto;
}

.custom-panel {
  max-height: 225px;
  overflow: auto;
}

#apply-values {
  background: $color-green;
  padding: 8px 0;
  color: $color-white;
}

.assign-group-background {
  background: #f5f7f9 !important;
}

.no-highlight {
  .mat-mdc-button-persistent-ripple.mdc-button__ripple {
    display: none !important;
  }
}

.tooltip-text-left {
  .mdc-tooltip__surface {
    text-align: left !important;
  }
}

.mat-mdc-form-field-type-phar-multiple-select {
  .mat-mdc-text-field-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
}

.icon-red {
  path {
    stroke: $color-red;
  }
}

.icon-blue {
  path {
    stroke: $color-primary;
  }
}

.header-with-menu {
  display: flex;
  align-items: center;

  mat-icon {
    zoom: 70%;
  }
}
.no-hover {
  &:hover {
    background-color: transparent !important;
    pointer-events: none;
  }
}
