@use '@angular/material' as mat;
@import '../../../variables.scss';

.element-hint {
  font-size: 13px !important;
  // font-weight: bold;
  // font-style: italic;
}

.data-bind-text {
  display: flex;
  justify-content: flex-start;
  color: mat.m2-get-color-from-palette($mat-black, 700);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  flex-wrap: wrap;
}
