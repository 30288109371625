@use '@angular/material' as mat;
@import '../../../variables.scss';
// #Button

button.button-success {
  @extend .background-success;
  color: $color-white !important;

  &.mat-mdc-button-disabled {
    background: $color-green-lighter !important;
    cursor: not-allowed;
  }
}

button.button-warn {
  @extend .background-warn;
  color: $color-white !important;
  &.mat-mdc-button-disabled {
    background: $color-red-lighter !important;
    cursor: not-allowed;
  }
}

button.button-warning {
  @extend .background-warning;
  color: $color-black !important;
  &.mat-mdc-button-disabled {
    color: $color-black-light !important;
    background: $color-orange-lighter !important;
    cursor: not-allowed;
  }
}

// 1. Button cancel
.button-cancel {
  border: 2px solid $color-outline !important;
}

// 2. Button with custom svg icon position fix
// !!!!!!!! This can break other icons.
// Best to create custom component.
button,
button.mat-mdc-button {
  mat-icon.mat-icon {
    display: flex !important;

    &.button__with-icon {
      // vertical-align: inherit !important;
      margin-right: 4px;
    }

    svg {
      vertical-align: unset;
    }
  }
}

// icon button
.phar-icon-button {
  border: 1px solid $color-outline;
  padding: 6px;
  border-radius: 4px;
  width: 38px;
  height: 38px;
  cursor: pointer;

  &:hover {
    background-color: mat.m2-get-color-from-palette($mat-black, 100);
    text-decoration: none;
  }
}

// Switch like buttons (2 options as in the list/grid switcher)
.active-switch-button {
  color: mat.m2-get-color-from-palette($mf-blue, 800) !important;
  background-color: mat.m2-get-color-from-palette($mf-blue, 300) !important;
}

.left-switch-button {
  border: 1px solid $color-outline !important;
  border-radius: 0 !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  letter-spacing: normal;
}

.middle-switch-button {
  border: 1px solid $color-outline !important;
  border-left: none !important;
  border-radius: 0 !important;
  letter-spacing: normal;
}

.right-switch-button {
  border: 1px solid $color-outline !important;
  border-left: none !important;
  border-radius: 0 !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  letter-spacing: normal !important;
}

.input_button_container {
  button {
    margin-bottom: 20px !important;
  }
}

.active-stroked-button {
  background-color: mat.m2-get-color-from-palette($primary, 700) !important;
  color: mat.m2-get-color-from-palette($mat-white, 100) !important;
}

.button-block {
  &_parent {
    height: 100%;
    position: relative;

    // Question and control buttons
    .button-block_button {
      visibility: visible !important;
      opacity: 1 !important;
      transition-delay: 0s !important;

      mat-icon {
        position: absolute;
        top: 5px;
        right: 5px;
        zoom: 85%;
      }

      .mat-mdc-button-touch-target {
        width: 30px;
        height: 30px;
      }
    }
  }

  &_holder {
    position: absolute;
    top: -40px;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: end;
  }

  &_holder_question {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    top: -60px;
    right: 0;
    align-items: center;
  }

  &_button {
    visibility: hidden !important;
    opacity: 0 !important;
    transition:
      visibility 0s linear 0.5s,
      opacity 0.5s linear !important;
    width: 30px !important;
    height: 30px !important;
  }
}

button mat-icon {
  width: 24px !important; //overriding the default mat-icon width and height
  height: 24px !important;
}

button.button-content-height {
  height: auto;
  padding-top: 7px;
  padding-bottom: 7px;
  min-height: var(--mdc-outlined-button-container-height);
}
