// This file contains styles specific to the question that is rendered after drag-and-drop
// The idea to have consistent styles.

@import '../../../variables.scss';

.question-label {
  font-weight: 600;
  font-size: 1.1rem;
}

.container-outline {
  border: 1px solid $color-outline;
  padding: 8px;
  border-radius: 8px;
  margin: 4px;
}

.form-text {
  font-weight: 600;
}

// Bootstrap override
.form-check-input {
  margin-top: 0;
}
