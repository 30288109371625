/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
//@import '@angular/material/core/theming/_palette.scss';
@import url('//fonts.googleapis.com/icon?family=Material+Icons');
@import 'assets/styles/typography.scss';
@import './variables';

@include mat.core();
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`


// define-typography-level function accepts, in order, CSS values for font-size, line-height, font-weight

//
//$mf-open-sans: mat.define-typography-config(
//        $font-family: 'OpenSans, sans-serif',
//        $body-1: mat.define-typography-level(16px, 24px, normal),
//        $input: mat.define-typography-level(inherit, 1.5, 400),
//);

$my-typography: mat.m2-define-typography-config(
  $font-family: 'Inter, sans-serif',
  $headline-5: mat.m2-define-typography-level(32px, 48px, bold),
  $headline-6: mat.m2-define-typography-level(14px, 48px, 600),
  $subtitle-1: mat.m2-define-typography-level(14px, 14px, 500),
  $subtitle-2: mat.m2-define-typography-level(10px, 10px, 500),
  $body-1: mat.m2-define-typography-level(14px, 1.5, 400),
  $body-2: mat.m2-define-typography-level(14px, 1.5, 400),
);

@include mat.all-component-typographies($my-typography);

.mat-typography {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: mat.m2-font-family($my-typography);
    font-weight: bold;
  }
}





$theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    typography: $my-typography,
    density: 0,
  )
);

@include mat.all-component-themes($theme);
