$size-base: 1rem;

$size-micro: 1rem * 0.25; // 4px
$size-mini: 1rem * 0.5; // 8px
$size-xsmall: $size-base * 0.65; //10px
$size-small: $size-base * 0.75; //12px
$size-medium: $size-base; //16px
$size-large: $size-base * 1.5; //24px
$size-xlarge: $size-base * 1.75; //28px
$size-xxlarge: $size-base * 2; //32px
