@use '@angular/material' as mat;
@import '../typography.scss';
@import '../../../variables.scss';

// INPUT
.mat-form-field-appearance-outline .mat-mdc-form-field-infix {
  padding: 7px !important;
  margin-top: 0 !important;
}

.mat-mdc-form-field-infix {
  padding: 0 !important;
  border-top: 0 !important;
  min-height: auto !important;
}

// Input and dropdown disabled state
mat-form-field {
  &.mat-form-field-disabled {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-outline {
      background-color: mat.m2-get-color-from-palette($mat-black, 50);
    }
  }
}

// #Dropdown
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: translateY(0%) !important;
}

// Checkbox
input[type='checkbox'] {
  width: $size-base;
  height: $size-base;
}

/* TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version.*/
.mat-input-small {
  &.mat-mdc-form-field .mat-mdc-form-field-wrapper {
    margin-bottom: 0;
    padding-bottom: 1.25em;
  }
}

.mat-mdc-form-filed-error {
  font-size: 0.75rem;
  padding: 0 1em;
}

textarea.mat-autosize {
  min-height: 20px !important;
}

.text-area-scrollable {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.text-area-scrollable::-webkit-scrollbar {
  display: none;
}

.mat-mdc-form-field:not(.mat-focused) .hint-focus-visible.mat-mdc-form-field-hint {
  display: none;
}

mat-label {
  display: block;
}

.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-outline {
  .mat-mdc-text-field-wrapper {
    height: 35px;
  }

  .mat-mdc-select-value {
    padding-bottom: 2px;
  }
}
