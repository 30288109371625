@use '@angular/material' as mat;
@import '../src/assets/styles/typography.scss';
@import './variables';

$fullheight: 100vh;

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-text {
  cursor: text;
}

.cursor-move {
  cursor: move !important;
}

.full-width {
  width: 100%;
}

//Three dots (multiline) on every element that goes out of the box
.three-dots {
  overflow: hidden;
  text-overflow: ellipsis;
}

//Three dots (singleline) perfect world  width need to be added
.three-dots-no-wrap {
  @extend .full-width;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.white-space-normal {
  white-space: normal;
}

.no-copy {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

//Positioning
.position {
  &-absolute {
    position: absolute;
  }

  &-relative {
    position: relative;
  }

  &-fixed {
    position: fixed;
  }
}

//floats
.float-left {
  float: left;
}

.float-right {
  float: right;
}

.full-height {
  height: $fullheight;

  &.fit {
    height: calc((#{$fullheight} - 50px) - 50px);
  }
}

.max {
  &-height-100 {
    max-height: 100%;
  }

  &-height-50 {
    max-height: 50%;
  }

  &-width-100 {
    max-width: 100%;
  }

  &-width-50 {
    max-width: 50%;
  }
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.overflow {
  &-hidden {
    overflow: hidden;
  }

  &-auto {
    overflow: auto;
  }
}

.max-width {
  max-width: 780px;
}

.background-transparent {
  background: transparent !important;
}

.underline-on-hover {
  &:hover {
    text-decoration: underline;
  }
}

.text-underline {
  text-decoration: underline;
}

.no-pointer-events {
  pointer-events: none;
}

.hidden {
  display: none;
}

@mixin scrollbar {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: mat.m2-get-color-from-palette($mat-white, A200);
    -webkit-box-shadow: inset 0 0 6px mat.m2-get-color-from-palette($mat-white, A200);
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: mat.m2-get-color-from-palette($mat-white, A200);
  }
}

@mixin beforeCardBox {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 6px;
  border-radius: 7px 0 0 7px;
  background: mat.m2-get-color-from-palette($primary, 0.25);
}

@mixin beforeCardBoxHover {
  @include mat.elevation(8);
}

@mixin asideHeader {
  padding: 10px 0;
  // border-top: 1px solid mat.get-color-from-palette($mat-white, A100);
  // border-bottom: 1px solid mat.get-color-from-palette($mat-white, A100);
  font-size: 14px;
  font-weight: bold;
  line-height: 1.2;
  .mat-icon {
    position: relative;
    top: -4px;
  }
}

@mixin asideHeaderBlue {
  color: mat.m2-get-color-from-palette($mf-blue, 500);
}

@mixin asideHeaderForList {
  margin-bottom: 0;
}

@mixin resizeHandler {
  .resize-handle {
    height: 100%;
    width: 2px;

    position: absolute;
    top: 0;
    left: 0;
    cursor: ew-resize;

    // prevent text selection while dragging
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    transition: background-color 0.2s ease-out;

    &:hover {
      width: 3px;
      background-color: $color-primary-light;

      &::after {
        content: '';
        display: block;

        height: 100%;

        width: 24px;

        position: absolute;
        right: -12px;
        z-index: 1;
      }
    }

    &.resizing::after {
      width: 100px;
      right: calc(-100px / 2);
    }
  }
}

.h-auto {
  height: auto;
}

.h-100 {
  height: 100%;
}

.t-0 {
  top: 0;
}

.b-0 {
  bottom: 0;
}

.l-0 {
  left: 0;
}

.r-0 {
  right: 0;
}

// Flex

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-end {
  align-items: flex-end;
}

.flex-grow {
  flex-grow: 1;
}

.inline-block {
  display: inline-block;
}

.small-icon {
  width: $size-medium !important;
  min-width: $size-medium !important;
  height: $size-medium !important;
}

//BUTTONS
button.reverse-icon {
  display: flex;
  flex-direction: row;

  mat-icon {
    margin: 0 0 0 8px !important;
  }
}
.text-strike-out {
  text-decoration: line-through;
}
