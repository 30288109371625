@use '@angular/material' as mat;
@import '../../../helpers';
@import '../../../variables.scss';
.widget-element {
  &_title {
    font-size: 12px;
    padding-top: 10px;
    color: mat.m2-get-color-from-palette($mat-black, 600) !important;
  }
}

.widget-element-small {
  border: 1px solid $color-line;
  border-radius: 5px;
  padding: 5px 10px;
}

//TODO fix naming of classes. The structure is not ok. Also the usage in HTML.

.widgets-container {
  &_content {
    height: calc(100% - var(--secondary-header-height) - var(--secondary-header-height-small));
    background: mat.m2-get-color-from-palette($mat-white, 900);
    display: grid;
    grid-gap: 0;
    grid-template-columns: 310px 1fr 310px;
    grid-template-rows: 100%;
    grid-template-areas: 'leftbar body rightbar';

    &_leftbar {
      grid-area: leftbar;
      height: 100%;
      background-color: mat.m2-get-color-from-palette($mat-white, 50);
      @include mat.elevation(2);
      padding: 20px 10px;
      overflow-y: auto;

      h6 {
        @include asideHeader;
        @include asideHeaderForList;
      }

      &_list {
        padding: 0 0;

        > * {
          width: 50%;
          height: 120px;
          display: inline-block;
          float: left;

          &:last-child:nth-child(odd):not(:first-child) {
            width: 141px;
          }
        }

        mfm-widget {
          display: flex;
          justify-content: center;
          align-items: flex-start;
        }
      }
    }

    &_rightbar {
      grid-area: rightbar;
      height: 100%;
      background-color: mat.m2-get-color-from-palette($mat-white, 50);
      padding-top: 20px;
      @extend .mat-elevation-z2;

      &_content {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        ::ng-deep {
          h6 {
            @include asideHeader;
            @include asideHeaderForList;
          }
        }
      }
    }
  }
}
