@use '@angular/material' as mat;

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

$mf-green: (
  50: #e2f0e7,
  100: #e2f0e7,
  200: #e2f0e7,
  300: #e2f0e7,
  400: #e2f0e7,
  500: #499f68,
  600: #499f68,
  700: #499f68,
  800: #499f68,
  900: #499f68,
  A100: #428f5e,
  A200: #428f5e,
  A400: #428f5e,
  A700: #428f5e,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);

$mf-teal: (
  50: #eaf2f5,
  100: #eaf2f5,
  200: #eaf2f5,
  300: #eaf2f5,
  400: #eaf2f5,
  500: #4f94ab,
  600: #4f94ab,
  700: #4f94ab,
  800: #4f94ab,
  900: #4f94ab,
  A100: #478092,
  A200: #478092,
  A400: #478092,
  A700: #478092,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);

$mf-red: (
  50: #fae3e2,
  100: #fae3e2,
  200: #fae3e2,
  300: #fae3e2,
  400: #dd5147,
  500: #dd5147,
  600: #dd5147,
  700: #dd5147,
  800: #dd5147,
  900: #dd5147,
  A100: #c74940,
  A200: #c74940,
  A400: #c74940,
  A700: #c74940,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);
$mf-yellow: (
  50: #fff2db,
  100: #fff2db,
  200: #fff2db,
  300: #fff2db,
  400: #fff2db,
  500: #ffaf21,
  600: #ffaf21,
  700: #ffaf21,
  800: #ffaf21,
  900: #ffaf21,
  A100: #e69e1e,
  A200: #e69e1e,
  A400: #e69e1e,
  A700: #e69e1e,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);
$mf-blue: (
  50: #e3e7f2,
  100: #e3e7f2,
  200: #e3e7f2,
  300: #e3e7f2,
  400: #4f66ab,
  500: #4f66ab,
  600: #4f66ab,
  700: #4f66ab,
  800: #4f66ab,
  900: #4f66ab,
  A100: #2d4c9e,
  A200: #2d4c9e,
  A300: #2d4c9e,
  A400: #2d4c9e,
  A500: #2d4c9e,
  A700: #2d4c9e,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);

$mat-white: (
  50: #ffffff,
  100: #ffffff,
  200: #ffffff,
  300: #ffffff,
  400: #f0f0f2,
  500: #f9f9f9,
  600: #fbfbfb,
  700: #f6f6f6,
  800: #f4f4f4,
  900: #f4f7f9,
  A100: #e8ebee,
  A200: #c7c9cd,
  A400: #9ea4ae,
  A700: #9b9b9b,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);
$mat-black: (
  50: #fbfbfb,
  100: #f6f6f7,
  200: #e5e5e6,
  300: #d7d7d7,
  400: #babbbd,
  500: #babbbd,
  600: #86878c,
  700: #86878c,
  800: #272931,
  900: #272931,
  A100: #272931,
  A200: #272931,
  A400: #272931,
  A700: #272931,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);

// 1. Colors
$color-primary: mat.m2-get-color-from-palette($mf-blue, A700);
$color-primary-light: mat.m2-get-color-from-palette($mf-blue, 700);
$color-primary-lighter: mat.m2-get-color-from-palette($mf-blue, 100);
$color-white: mat.m2-get-color-from-palette($mat-white, 50);
$color-white-background: mat.m2-get-color-from-palette($mat-white, 700);

// General
$color-black: mat.m2-get-color-from-palette($mat-black, A700);
$color-black-light: mat.m2-get-color-from-palette($mat-black, 700);
$color-black-lighter: mat.m2-get-color-from-palette($mat-black, 100);

$color-teal: mat.m2-get-color-from-palette($mf-teal, A700);

$color-orange: mat.m2-get-color-from-palette($mf-yellow, A700);
$color-orange-light: mat.m2-get-color-from-palette($mf-yellow, 700);
$color-orange-lighter: mat.m2-get-color-from-palette($mf-yellow, 100);

$color-green: mat.m2-get-color-from-palette($mf-green, A700);
$color-green-light: mat.m2-get-color-from-palette($mf-green, 700);
$color-green-lighter: mat.m2-get-color-from-palette($mf-green, 100);

$color-red: mat.m2-get-color-from-palette($mf-red, A700);
$color-red-light: mat.m2-get-color-from-palette($mf-red, 700);
$color-red-lighter: mat.m2-get-color-from-palette($mf-red, 100);

$color-menu-active: mat.m2-get-color-from-palette($mf-blue, A700);

// Buttons
$color-outline: mat.m2-get-color-from-palette($mat-black, 500);
$btn-background: mat.m2-get-color-from-palette($mat-black, 50);

// Lines
$color-line: mat.m2-get-color-from-palette($mat-black, 300);
$color-line-lighter: mat.m2-get-color-from-palette($mat-black, 200);

// 2. Sizes
$size-header: 46px;

$light-primary-text: white;
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$primaryTheme: $mf-blue;
$accentTheme: $mf-yellow;
$warnTheme: $mf-red;
$successTheme: $mf-green;
$tealTheme: $mf-teal;
$elevation: 700;
$primary: mat.m2-define-palette($primaryTheme, $elevation);
$accent: mat.m2-define-palette($accentTheme, $elevation);
$warn: mat.m2-define-palette($warnTheme, $elevation);
$success: mat.m2-define-palette($successTheme, $elevation);
$successText: mat.m2-define-palette($successTheme, A700);
$teal: mat.m2-define-palette($tealTheme, $elevation);


$mf-bw-modelica: mat.m2-define-typography-config(
        $font-family: 'BwModelica, monospace',
        $headline-5: mat.m2-define-typography-level(32px, 48px, bold),
        $headline-6: mat.m2-define-typography-level(14px, 48px, 600),
        $subtitle-1: mat.m2-define-typography-level(14px, 14px, 500),
        $subtitle-2: mat.m2-define-typography-level(10px, 10px, 500),
        $body-1: mat.m2-define-typography-level(inherit, 1.5, 400),
);
