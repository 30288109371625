/* Breakpoints for Media Queries */

@mixin for-phone-only {
  @media only screen and (max-width: 576px) {
    @content;
  }
}

@mixin for-tablet {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin for-desktop {
  @media only screen and (max-width: 992px) {
    @content;
  }
}

@mixin for-desktop-xl {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}
