@font-face {
  font-family: 'OpenSans';
  src: url('/assets/fonts/OpenSans Font/OpenSans-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('/assets/fonts/OpenSans Font/OpenSans-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('/assets/fonts/OpenSans Font/OpenSans-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'OpenSans';
  src: url('/assets/fonts/OpenSans Font/OpenSans-SemiBoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: 'BwModelica';
  src: url('/assets/fonts/BwModelicaLGCMedium.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'BwModelica';
  src: url('/assets/fonts/BwModelicaLGCMediumItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: 'BwModelica';
  src: url('/assets/fonts/BwModelicaLGCBold.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'BwModelica';
  src: url('/assets/fonts/BwModelicaLGCBoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Inter';
  src: url('/assets/fonts/Inter/Inter-Black.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'icomoon';
  src: url('/assets/fonts/icomoon/icomoon.eot?xn53bv');
  src:
    url('/assets/fonts/icomoon/icomoon.eot?xn53bv#iefix') format('embedded-opentype'),
    url('/assets/fonts/icomoon/icomoon.ttf?xn53bv') format('truetype'),
    url('/assets/fonts/icomoon/icomoon.woff?xn53bv') format('woff'),
    url('/assets/fonts/icomoon/icomoon.svg?xn53bv#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checkbox_active:before {
  content: '\e907';
  color: #90949c;
}

.icon-checkbox_inactive:before {
  content: '\e909';
  color: #90949c;
}

.icon-add_user_oncard:before {
  content: '\e90a';
  color: #90949c;
}

.icon-preview_form_oncard:before {
  content: '\e90b';
  color: #90949c;
}

.icon-search:before {
  content: '\e90c';
  color: #90949c;
}

.icon-plus_add:before {
  content: '\e90d';
  color: #90949c;
}

.icon-groups:before {
  content: '\e90e';
  color: #90949c;
}

.icon-users:before {
  content: '\e90f';
  color: #90949c;
}

.icon-close:before {
  content: '\e910';
  color: #90949c;
}

.icon-view_form:before {
  content: '\e911';
  color: #90949c;
}

.icon-forms_outline:before {
  content: '\e912';
  color: #90949c;
}

.icon-records:before {
  content: '\e913';
  color: #90949c;
}

.icon-organization:before {
  content: '\e914';
  color: #90949c;
}

.icon-open:before {
  content: '\e915';
  color: #90949c;
}

.icon-assign:before {
  content: '\e916';
  color: #90949c;
}

.icon-datasets:before {
  content: '\e900';
  color: #90949c;
}

.icon-details:before {
  content: '\e901';
  color: #90949c;
}

.icon-forms:before {
  content: '\e902';
  color: #90949c;
}

.icon-howto:before {
  content: '\e903';
  color: #90949c;
}

.icon-forms:before {
  content: '\e902';
  color: #90949c;
}

.icon-reports:before {
  content: '\e917';
  color: #90949c;
}

.icon-library:before {
  content: '\e904';
  color: #90949c;
}

.icon-survey:before {
  content: '\e918';
  color: #90949c;
}

.icon-project_records:before {
  content: '\e905';
  color: #90949c;
}

.icon-projects:before {
  content: '\e906';
  color: #90949c;
}

.icon-rules:before {
  content: '\e908';
  color: #90949c;
}

.icon-list:before {
  content: '\e9ba';
  color: #90949c;
}

.icon-equalizer:before {
  content: '\e992';
  color: #90949c;
}

.icon-history:before {
  content: '\e94d';
  color: #c7c9cd;
}
